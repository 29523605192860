


.box{
    background-color: aliceblue;
    margin: 2px;
    padding-inline: 12px;
    padding-top: 1px;
    padding-bottom: 1px;
}

/* .error
{
color: Red;
font-family: 'Work Sans', sans-serif;
font-size:11px;
font-style: italic;
} */
