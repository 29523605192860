/* Navbar.css */
nav {
    
    background-color: #333;
  }
  
  ul {
    display: flex;
    justify-content: space-between;
    list-style-type: none;
    margin: 0;
    padding: 0;
  }
  
  li {
    display: inline;
  }
  
  a {
    display: block;
    padding: 10px 20px;
    color: white;
    text-decoration: none;
  }
  
  a:hover {
    background-color: #555;
  }
  